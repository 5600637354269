import { createRouter, createWebHistory } from 'vue-router'

import Home_tool from '@/components/Home.vue'
import List_tool from '@/components/List.vue'
import Result_tool from '@/components/Result.vue'

const routes = [
    {
        path: '/',
        name: 'Home_tool',
        component: Home_tool
    },
    {
        path: '/danh-sach-ban',
        name: 'List_tool',
        component: List_tool
    },
    // {
    //     path: '/du-doan/:id/:percent',
    //     name: 'Result_tool',
    //     component: Result_tool
    // },
    {
        path: '/du-doan/:id/:percent/:name',
        name: 'Result_tool',
        component: Result_tool
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    linkActiveClass: "active"
})

export default router