<template>
    <div class="wrapper_home mg_top">
        <div class="container ner_responesize">
            <div class="wp_result_tool">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12 right_chart">
                        <div class="wp_chart">
                            <h3 class="title_nametable">Bàn {{ nameTable }}</h3>
                            <div class="note_chart">
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-4 wp_item_number">
                                        <div class="tonggiaodichrow">
                                            <h4>PLAYER </h4>
                                            <!-- <h5>Tổng: {{ formatNumber(xanhTong) }}</h5> -->
                                            <h5>Tổng: {{ formatNumber(randomGDX) }}</h5>
                                            <div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Số tiền GD</th>
                                                            <th>Thời gian</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="giaodichxanh in loadMoreListXanh" :key="giaodichxanh.id" class="up_down">
                                                            <td id="updown" :class="{ 'up': giaodichxanh.changes > 0, 'down': giaodichxanh.changes < 0 }">{{ formatNumber(giaodichxanh.value) }}</td>
                                                            <td>{{ formattedTime(giaodichxanh.timestamp) }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p class="loadsmore_data loadsmore_datax" v-if="length_xanh < giaodichxanh.length"><span @click="loadMoreXanh">Xem thêm</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-4 wp_item_number">
                                        <div class="tonggiaodichrow">
                                            <div class="tonggiaodichrow">
                                                <h4>TIE</h4>
                                                <!-- <h5>Tổng: {{ formatNumber(hoatong) }}</h5> -->
                                                <h5>Tổng: {{ formatNumber(randomGDH) }}</h5>
                                                <div>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Số tiền GD</th>
                                                                <th>Thời gian</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="giaodichhoa in loadMoreListHoa" :key="giaodichhoa.id" class="up_down">
                                                                <td id="updown" :class="{ 'up': giaodichhoa.changes > 0, 'down': giaodichhoa.changes < 0 }">{{ formatNumber(giaodichhoa.value) }}</td>
                                                                <td>{{ formattedTime(giaodichhoa.timestamp) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <p class="loadsmore_data loadsmore_datah" v-if="length_hoa < giaodichhoa.length"><span @click="loadMoreHoa">Xem thêm</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-4 wp_item_number">
                                        <div class="tonggiaodichrow">
                                            <div class="tonggiaodichrow">
                                                <h4>BANKER</h4>
                                                <!-- <h5>Tổng: {{ formatNumber(dotong) }}</h5> -->
                                                <h5>Tổng: {{ formatNumber(randomGDD) }}</h5>
                                                <div>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Số tiền GD</th>
                                                                <th>Thời gian</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="giaodichdo in loadMoreListDo" :key="giaodichdo.id" class="up_down">
                                                                <td id="updown" :class="{ 'up': giaodichdo.changes > 0, 'down': giaodichdo.changes < 0 }">{{ formatNumber(giaodichdo.value) }}</td>
                                                                <td>{{ formattedTime(giaodichdo.timestamp) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <p class="loadsmore_data loadsmore_datad" v-if="length_do < giaodichdo.length"><span @click="loadMoreDo">Xem thêm</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-4 wp_item_note">
                                        <div class="item_note">
                                            <h3>{{percentages.columnP}}%</h3>
                                            <p class="pxd">P</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-4 wp_item_note">
                                        <div class="item_note">
                                            <h3>{{percentages.columnT}}%</h3>
                                            <p class="pxl">T</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-4 wp_item_note">
                                        <div class="item_note">
                                            <h3>{{percentages.columnB}}%</h3>
                                            <p class="pd">B</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 left_info" v-if="checkExp == 0">
                        <div class="result_frm">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-6 item_dudoan">
                                    <div class="wp_dudoan_root">
                                        <p class="tt_dd">Dự đoán kết quả sau <span :class="cl_red">{{ time }}s</span></p>
                                        <div class="wp_dudoan">
                                            <div class="circle">
                                                <div class="circle-inner" :style="{ 'background': `conic-gradient(#06EE23 0% ${urlpercent}%, rgba(0,0,0,0) 50% 100%)` }"></div>
                                                <div class="circle-overlay"></div>
                                                <span class="percentage-text">{{ urlpercent }}%</span>
                                            </div>
                                        </div>
                                        <div class="wp_dudoan">
                                            <div class="loading-circle" v-if="loading == 0">
                                                <div class="inner-circle inner-circlep" v-if="percentages.columnP > percentages.columnB">
                                                    <span class="icon">P</span>
                                                </div>
                                                <div class="inner-circle inner-circleb" v-else>
                                                    <span class="icon">B</span>
                                                </div>
                                            </div>
                                            <div class="loading-result" v-else>
                                                <p>Ngưng cược</p>
                                                <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6 item_dudoan">
                                    <div class="root_ratio_game">
                                        <div class="grid-container">
                                            <div class="grid" v-if="result.length <= 0">
                                                <div v-for="i in 72" :key="i" class="grid-cell"></div>
                                            </div>
                                            <div class="grid" v-else>
                                                <div v-for="(cell, index) in result" :key="index" class="grid-cell">
                                                    <span :class="'spancell span' + (cell && getFirstCharacter(cell))">{{ cell && getFirstCharacter(cell) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- modal thông báo -->
                <div id="modal-example" class="modal-changepass">
                    <div class="overlay" v-if="showModalTb" @click="showModalTb = false"></div>
                    <div class="modal" v-if="showModalTb">
                        <div class="wp-modal-changepass">
                            <div class="content-modal_tab">
                                <div>
                                    <div class="frm_change_point">
                                        <div class="wp_notifi">
                                            <h3>Tài khoản không đủ điểm</h3>
                                            <p>VUI LÒNG GỬI YÊU CẦU ĐẾN NGƯỜI HƯỚNG DẪN ĐĂNG KÝ TOOL ĐỂ ĐƯỢC HỖ TRỢ NHANH NHẤT HOẶC LIÊN HỆ ADMIN</p>
                                            <div class="item_inputchange">
                                                <button @click="goBackExp" type="button">Xác nhận</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal thông báo -->
                
            </div>
        </div>
    </div>
</template>

<script>
// import Chart_tool from '@/components/Chart.vue'
import $ from 'jquery'
export default {
    name: 'Result_tool',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            data: [],
            result: [],
            urlpercent: this.$route.params.percent,
            urlId: this.$route.params.id,
            nameTable: this.$route.params.name,
            idLast: 0,
            percentages: {
                columnP: 0,
                columnT: 0,
                columnB: 0,
            },
            giaodichxanh: [
                { id: 1, value: 1400000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 2, value: 4200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 3, value: 6800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 4, value: 8500000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 5, value: 12600000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 6, value: 32000000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 7, value: 37800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 8, value: 45900000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 9, value: 52700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 10, value: 61200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 11, value: 65400000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 12, value: 69800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 13, value: 73700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 14, value: 86400000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 15, value: 91200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 16, value: 132700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 17, value: 168900000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 18, value: 256300000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 19, value: 386900000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 20, value: 500000000, change: 0, changes: 0, timestamp: this.randomTimestamp() }
            ],
            giaodichhoa: [
                { id: 1, value: 1100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 2, value: 2600000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 3, value: 3500000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 4, value: 5700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 5, value: 8100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 6, value: 11200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 7, value: 14600000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 8, value: 26700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 9, value: 29800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 10, value: 42100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 11, value: 57800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 12, value: 61000000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 13, value: 68100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 14, value: 75400000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 15, value: 81300000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 16, value: 94700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 17, value: 111000000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 18, value: 216000000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 19, value: 257800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 20, value: 289900000, change: 0, changes: 0, timestamp: this.randomTimestamp() }
            ],
            giaodichdo: [
                { id: 1, value: 1000000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 2, value: 3600000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 3, value: 8700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 4, value: 12500000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 5, value: 19700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 6, value: 21800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 7, value: 25100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 8, value: 46300000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 9, value: 59800000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 10, value: 71200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 11, value: 86900000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 12, value: 91100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 13, value: 93200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 14, value: 126900000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 15, value: 151100000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 16, value: 189200000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 17, value: 210000000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 18, value: 258700000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 19, value: 325600000, change: 0, changes: 0, timestamp: this.randomTimestamp() },
                { id: 20, value: 500000000, change: 0, changes: 0, timestamp: this.randomTimestamp() }
            ],
            tonggiaodichxanh: [20256000000, 27265000000, 32259000000, 40210000000, 45695000000, 52025000000, 61250000000, 76149000000, 85928000000, 98698000000],
            randomGDX: 23265000000,
            tonggiaodichhoa: [565000000, 743000000, 879000000, 940000000, 1256000000, 2100000000, 2658000000, 3630000000, 4179000000, 4836000000, 4962000000],
            randomGDH: 743000000,
            tonggiaodichdo: [21600000000, 25450000000, 28785000000, 51256000000, 57814000000, 65231000000, 69511000000, 78232000000, 87489000000, 91164000000 ],
            randomGDD: 32785000000,

            xanhTong: 16126000000,
            hoatong: 512000000,
            dotong: 17236000000,
            arrNumber: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20 ],
            length_xanh: 10,
            length_hoa: 10,
            length_do: 10,
            strResult: [],
            loading: 0,
            time: 25,
            interval: null,
            cl_red: '',
            intervalId: null,
            intervalId1: null,
            intervalId2: null,
            intervalId3: null,
            showModalTb: false,
            checkExp: 0,
            audio: null,
        }
    },
    components: {
        // Chart_tool,
    },
    computed: {
        loadMoreListXanh() {
            return this.giaodichxanh.slice(0, this.length_xanh);
        },
        loadMoreListHoa() {
            return this.giaodichhoa.slice(0, this.length_hoa);
        },
        loadMoreListDo() {
            return this.giaodichdo.slice(0, this.length_do);
        },
    },
    mounted () {
        this.generatePercentages();
        this.updateDataArray()
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    },
    created () {
        this.getDataString()
        this.intervalId3 = setInterval(() => {
            this.getDataString()
        }, 3000);
        this.startCountdown()
        this.getInfoUser()
    },
    beforeUnmount() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        sessionStorage.removeItem('result');
        // sessionStorage.removeItem('game_round');
        clearInterval(this.intervalId3);
    },
    methods: {
        handleVisibilityChange() {
            if (document.visibilityState === 'hidden') {
                setTimeout(() => {
                    this.$router.push('/danh-sach-ban');
                }, 15000);
            }
        },
        getDataString () {
            this.axios.get(this.api_listResult + '?roomId=' + this.urlId).then((response) => {
                this.strResult = response.data.data.results.map(item => item.resultValue).join('');
                // if(strResult.length > 5){
                //     this.strResult = strResult.slice(-5)
                // }else{
                //     this.strResult = response.data.data.results.map(item => item.resultValue).join('')
                // }
                
                // this.strResult = response.data.data.filter(d => d.id == this.urlId)
                // const str = this.strResult[0].result



                // this.data = Array.from(this.strResult)
                // const rows = window.innerWidth > 601 ? 12 : 9;
                // const cols = window.innerWidth > 601 ? 6 : 8;
                // this.result = []
                // for (let col = 0; col < cols; col++) {
                //     for (let row = 0; row < rows; row++) {
                //         this.result.push(this.data[row * cols + col] || null)
                //     }
                // }

                const dataOld_game_round = sessionStorage.getItem("game_round");
                const dataNew_game_round = this.strResult
                if (dataOld_game_round != dataNew_game_round) {
                    sessionStorage.setItem("game_round", dataNew_game_round);
                    this.axios.get(this.api_subPoint, {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }).then((response) => {
                        let exp = response.data.data.exp
                        $('.exp_user b').text(this.formatNumber(exp))
                        if(exp <= 0){
                            setTimeout(() => {
                                this.checkExp = 1
                                this.showModalTb = true
                            }, 3000);
                        }else{
                            this.checkExp = 0
                        }
                    })
                }
                
                const dataOld = sessionStorage.getItem("result");
                const rows = window.innerWidth > 601 ? 12 : 9;
                const cols = window.innerWidth > 601 ? 6 : 8;
                const totalCells = rows * cols;
                if(this.strResult.length > totalCells){
                    const dataNew = this.strResult.slice(-totalCells)
                    if (dataOld !== dataNew) {
                        // console.log("Giá trị đã thay đổi:", dataOld, "=>", dataNew);
                        sessionStorage.setItem("result", dataNew);
                        this.data = Array.from(dataNew)
                        this.result = []
                        for (let col = 0; col < cols; col++) {
                            for (let row = 0; row < rows; row++) {
                                this.result.push(this.data[row * cols + col] || null);
                            }
                        }
                        this.result = [...this.result];
                        this.loading = 1
                        this.time = 0
                        this.updateAddGD()
                        setTimeout(() => {
                            this.loading = 0
                            this.generatePercentages()
                            this.cl_red = ''
                            this.startCountdown()
                            this.updateDataArray()
                        }, 2000);
                    } else {
                        // console.log("Giá trị không thay đổi:", dataNew);
                        const str = this.strResult.slice(-totalCells)
                        this.data = Array.from(str)
                        this.result = []
                        for (let col = 0; col < cols; col++) {
                            for (let row = 0; row < rows; row++) {
                                this.result.push(this.data[row * cols + col] || null);
                            }
                        }
                        this.result = [...this.result];
                    }
                }else{
                    const dataNew = this.strResult
                    if (dataOld !== dataNew) {
                        // console.log("Giá trị đã thay đổi:", dataOld, "=>", dataNew);
                        sessionStorage.setItem("result", dataNew);
                        this.data = Array.from(dataNew)
                        this.result = []
                        for (let col = 0; col < cols; col++) {
                            for (let row = 0; row < rows; row++) {
                                this.result.push(this.data[row * cols + col] || null)
                            }
                        }
                        this.loading = 1
                        this.time = 0
                        this.updateAddGD()
                        setTimeout(() => {
                            this.loading = 0
                            this.generatePercentages()
                            this.cl_red = ''
                            this.startCountdown()
                            this.updateDataArray()
                        }, 2000);
                    } else {
                        // console.log("Giá trị không thay đổi:", dataNew);
                        const str = this.strResult
                        this.data = Array.from(str)
                        this.result = []
                        for (let col = 0; col < cols; col++) {
                            for (let row = 0; row < rows; row++) {
                                this.result.push(this.data[row * cols + col] || null)
                            }
                        }
                    }
                }
                
                if(this.time == 0){
                    clearInterval(this.intervalId)
                    clearInterval(this.intervalId1)
                    clearInterval(this.intervalId2)
                }
            })
        },
        getFirstCharacter(str) {
            return str.charAt(0);
        },
        reverseArray(arr) {
            arr.reverse();
        },
        generatePercentages() {
            let columnT = Math.floor(Math.random() * 20); // Random từ 0 đến 29
            let columnP = Math.floor(Math.random() * (100 - columnT));
            let columnB = 100 - columnT - columnP

            const values = [columnT, columnP, columnB].sort((x, y) => x - y);
            if(this.randomGDX < this.randomGDD){
                this.percentages = {
                    columnP: values[2],
                    columnT: values[0],
                    columnB: values[1],
                };
            }else{
                this.percentages = {
                    columnP: values[1],
                    columnT: values[0],
                    columnB:  values[2],
                };
            }
        },
        formatNumber(value) {
            // vi-VN
            return value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
        },
        randomTimestamp() {
            const now = Date.now();
            const fiveHoursAgo = now - 60 * 1000;
            // const fiveHoursAgo = now - 2 * 60 * 60 * 1000;
            return Math.floor(Math.random() * (now - fiveHoursAgo + 1) + fiveHoursAgo);
        },
        updateAddGD () {
            const randomIndex = Math.floor(Math.random() * this.tonggiaodichxanh.length);
            this.randomGDX = this.tonggiaodichxanh[randomIndex];

            const randomIndexh = Math.floor(Math.random() * this.tonggiaodichhoa.length);
            this.randomGDH = this.tonggiaodichhoa[randomIndexh];

            const randomIndexd = Math.floor(Math.random() * this.tonggiaodichdo.length);
            this.randomGDD = this.tonggiaodichdo[randomIndexd];
        },
        updateDataArray() {
            this.intervalId = setInterval(() => {
                // Giao dịch xanh
                const randomArray = Math.floor(Math.random() * this.arrNumber.length);
                const valRandom = this.arrNumber[randomArray];
                this.xanhTong = this.xanhTong + valRandom * 1000000;
                this.giaodichxanh = this.giaodichxanh.map(giaodichxanh => {
                    const change = Math.random() * 0.0009;
                    const changes = Math.random() * 0.0018 - 0.0009;
                    // const change = (Math.random() - 0.5) * 2;
                    // value: Math.round((giaodichxanh.value + giaodichxanh.value * change / 100) * 100) / 100,
                    return {
                        ...giaodichxanh,
                        value: giaodichxanh.value,
                        change: change,
                        changes: changes,
                        timestamp: this.randomTimestamp(),
                    }
                }).sort((a, b) => a.timestamp - b.timestamp);
            }, 3000)
            this.intervalId1 = setInterval(() => {
                // Giao dịch hòa
                const randomArray = Math.floor(Math.random() * this.arrNumber.length);
                const valRandom = this.arrNumber[randomArray];
                this.hoatong = this.hoatong + valRandom * 1000000;

                this.giaodichhoa = this.giaodichhoa.map(giaodichhoa => {
                    const change = Math.random() * 0.0009;
                    const changes = Math.random() * 0.0018 - 0.0009;
                    return {
                        ...giaodichhoa,
                        value: giaodichhoa.value,
                        change: change,
                        changes: changes,
                        timestamp: this.randomTimestamp()
                    }
                }).sort((a, b) => a.timestamp - b.timestamp);
            }, 4000)
            this.intervalId2 = setInterval(() => {
                // Giao dịch đỏ
                const randomArray = Math.floor(Math.random() * this.arrNumber.length);
                const valRandom = this.arrNumber[randomArray];
                this.dotong = this.dotong + valRandom * 1000000;

                this.giaodichdo = this.giaodichdo.map(giaodichdo => {
                    const change = Math.random() * 0.0009;
                    const changes = Math.random() * 0.0018 - 0.0009;
                    return {
                        ...giaodichdo,
                        value: giaodichdo.value,
                        change: change,
                        changes: changes,
                        timestamp: this.randomTimestamp()
                    }
                }).sort((a, b) => a.timestamp - b.timestamp);
            }, 3500)
        },
        loadMoreXanh() {
            if (this.length_xanh > this.giaodichxanh.length) return;
            this.length_xanh = this.length_xanh + 10;
        },
        loadMoreHoa() {
            if (this.length_hoa > this.giaodichhoa.length) return;
            this.length_hoa = this.length_hoa + 10;
        },
        loadMoreDo() {
            if (this.length_do > this.giaodichdo.length) return;
            this.length_do = this.length_do + 10;
        },
        formattedTime(d) {
            const date = new Date(d);
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            // const year = date.getFullYear();
            return `${hours}:${minutes}:${seconds} ${day}/${month}`;
        },
        startCountdown() {
            // Nếu bộ đếm đang chạy, dừng lại trước khi bắt đầu lại
            if (this.interval) {
                clearInterval(this.interval);
            }
            this.time = 20;
            this.interval = setInterval(() => {
                if(this.time <= 11){
                    this.cl_red = 'cl_red'
                }else{
                    this.cl_red = ''
                }
                if (this.time > 0) {
                    this.time--;
                } else {
                    // this.loading = 1
                    // clearInterval(this.interval); // Dừng bộ đếm khi thời gian kết thúc
                }
            }, 1000);
        },
        getInfoUser () {
            this.axios.get(this.api_InfoUser, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.point = response.data.data.user.exp
                if(this.point <= 0){
                    this.checkExp = 1
                    this.showModalTb = true
                }else{
                    this.checkExp = 0
                }
            }).catch((res) => {
                if(res.response.status == 401){
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 1000);
                }
            })
        },
        goBackExp() {
            this.$router.back();
        },
    }
}
</script>
<style scoped>
</style>