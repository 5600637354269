<template>
    <div class="root_login">
        <div class="img_hacker_took">
            <!-- <img src="../assets/images/hk.gif" alt=""> -->
            <p class="cloud-text">TOOL EXPLOITATION</p>
        </div>
        <form class="frm_input frm_login_app" action="" method="post" id="frm_login" @submit="checkUser" v-if="checkFrm == 1">
            <div class="input_modal">
                <input type="text" v-model="username" ref="input_username" placeholder="Tên đăng nhập">
                <img src="../assets/images/us.png" alt="" class="icon_input">
            </div>
            <div class="btn_modal_login_tool">
                <div class="btn_login">
                    <button type="submit">Tiếp theo</button>
                </div>
            </div>
        </form>
        <form class="frm_input frm_register_app" action="" method="post" id="frm_registerapp" @submit="login" v-else>
            <div class="input_modal">
                <label>Mật khẩu</label>
                <input type="password" ref="input_password" v-model="password" placeholder="">
                <!-- <img src="../assets/images/pw.png" alt="" class="icon_input"> -->
            </div>
            <div class="btn_modal_login_tool">
                <div class="btn_login">
                    <button type="submit">Đăng nhập</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Login_tool',
    data () {
        return {
            username: '',
            password: '',
            checkFrm: 1,
            username_lg: ''
        }
    },
    components: {
        
    },
    mounted() {
        this.$refs.input_username.focus();
    },
    created () {
    },
    methods: {
        checkUser (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.get(this.api_CheckName + '?username=' + this.username).then((response) => {
                if(response.data.data.check_user == 0){
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Tài khoản không tồn tại',
                        showConfirmButton: false,
                        timer: 3000
                    })
                }else{
                    $('#loading_show').css('display', 'none')
                    this.username_lg = this.username
                    this.checkFrm = 2
                    setTimeout(() => {
                        this.$refs.input_password.focus();
                    }, 500);
                }
            })
        },
        login (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_login, {
                username: this.username_lg,
                password: this.password,
            }).then(res => {
                $('#loading_show').css('display', 'none')
                window.localStorage.setItem('token', res.data.data.token)
                window.localStorage.setItem('userId', res.data.data.user.id)
                window.localStorage.setItem('userName', res.data.data.user.username)
                // window.localStorage.setItem('nameGroup', res.data.data.user.name_group)
                // window.localStorage.setItem('idGroup', res.data.data.user.id_group)
                let today = new Date();
                let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
                let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                let timestamp = date + ' ' + time;
                window.localStorage.setItem('time_token', timestamp)
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.checkFrm = 1
                window.location.reload()
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
    }
}
</script>
<style>
    
</style>
